import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { isEmpty } from 'lodash';
import OneSignal from 'react-onesignal';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import runOneSignal from '../utils/onesignal';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages
    };
  },
  LOGIN: (state, action) => {
    const { user, typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages
    };
  },
  GOOGLELOGIN: (state, action) => {
    const { user, typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages
    };
  },
  FACEBOOKLOGIN: (state, action) => {
    const { user, typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      typeStatuses,
      categories,
      categoriesDetail,
      cities,
      configs,
      siteAdPackages
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    typeStatuses: [],
    categories: [],
    categoriesDetail: [],
    cities: [],
    configs: {},
    siteAdPackages: []
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  googleLogin: () => Promise.resolve(),
  facebookLogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verify: () => Promise.resolve()
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, refreshToken);
          const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
          const user = await axios.get('api/v1/users/me/');
          // oneSignalHandler(user?.data);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: user?.data || {},
              typeStatuses,
              categories,
              categoriesDetail,
              cities,
              configs,
              siteAdPackages
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              typeStatuses: [],
              categories: [],
              categoriesDetail: [],
              cities: [],
              configs: {},
              siteAdPackages: []
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            typeStatuses: [],
            categories: [],
            categoriesDetail: [],
            cities: [],
            configs: {},
            siteAdPackages: []
          },
        });
      }
    };

    initialize();
  }, []);

  const getCommonCatalog = async () => {
    const categoriesDetail = [];
    const siteAdPackages = [];
    const configsResponse = await axios.get('api/v3/commons/configs/');

    const configs = configsResponse.data;
    let cities = configs?.cities;
    let typeStatuses = configs?.type_statuses;
    let categories = configs?.categories;

    if (typeStatuses?.length > 0) {
      typeStatuses = typeStatuses.map((type) => ({
        ...type,
        id: type?.id,
        label: type?.alias,
      }));
    }

    if (categories?.length > 0) {
      categories = categories.map((category) => ({
        ...category,
        label: category?.alias,
        children: category.children.map((categoryDetail) => ({
          ...categoryDetail,
          label: categoryDetail.alias,
          parentId: category.id
        }))
      }));
      categories.forEach((category) => {
        if (category?.children) {
          const children = category?.children;
          children.forEach((child) => {
            categoriesDetail.push({
              id: child.id,
              label: child.alias,
              alias: child.alias,
              level: child.level,
              name: child.name,
              parentId: category.id
            });
          });
        }
      });
    }

    if (cities?.length) {
      cities = cities.map((city) => ({
        ...city,
        id: city?.id,
        label: city?.alias,
      }))
    }
    return { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages }
  }

  const login = async (email, password, googleResponseCaptcha) => {
    const response = await axios.post('api/token/', {
      username: email,
      password,
      'g-recaptcha-response': googleResponseCaptcha
    });
    const { access, refresh } = response.data;

    setSession(access, refresh);
    const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
    const user = await axios.get('api/v1/users/me/').catch(err => console.error(err));
    oneSignalHandler(user?.data);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: user?.data || {},
        typeStatuses,
        categories,
        categoriesDetail,
        cities,
        configs,
        siteAdPackages
      },
    });
  };

  const verify = async (accessToken, refreshToken) => {
    try {
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken, refreshToken);
        const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
        const user = await axios.get('api/v1/users/me/');
        oneSignalHandler(user?.data);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: user?.data || {},
            typeStatuses,
            categories,
            categoriesDetail,
            cities,
            configs,
            siteAdPackages,
          },
        });
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            typeStatuses: [],
            categories: [],
            categoriesDetail: [],
            cities: [],
            configs: {},
            siteAdPackages: [],
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
          typeStatuses: [],
          categories: [],
          categoriesDetail: [],
          cities: [],
          configs: {},
          siteAdPackages: [],
        },
      });
    }
  };

  const googleLogin = async (code) => {
    console.log('call api sso');
    const response = await axios.post('api/sso/social/jwt-pair-user/google-oauth2/', {
      'code': code,
      'redirect_uri': window.location.href
    });

    const { token, refresh } = response.data;

    setSession(token, refresh);
    const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
    const user = await axios.get('api/v1/users/me/');
    oneSignalHandler(user?.data);
    dispatch({
      type: 'GOOGLELOGIN',
      payload: {
        user: user?.data || {},
        typeStatuses,
        categories,
        categoriesDetail,
        cities,
        configs,
        siteAdPackages
      },
    });
  };

  const facebookLogin = async (responsePrompt) => {
    // Base64 Encoded String
    const split = responsePrompt.signedRequest.split('.');
    const base64string = split[split.length - 1];
    // Creating the buffer object with utf8 encoding
    const bufferObj = Buffer.from(base64string, "base64");
    // Decoding base64 into String
    const string = bufferObj.toString("utf8");
    // Printing the base64 decoded string
    const decodedSigned = JSON.parse(string);
    const response = await axios.post('api/sso/social/jwt-pair-user/facebook/', {
      'code': decodedSigned.code,
      'redirect_uri': `${window.location.protocol}//${window.location.host}/`
    });

    const { token, refresh } = response.data;

    setSession(token, refresh);
    const { typeStatuses, categories, categoriesDetail, cities, configs, siteAdPackages } = await getCommonCatalog();
    const user = await axios.get('api/v1/users/me/');
    oneSignalHandler(user?.data);
    dispatch({
      type: 'FACEBOOKLOGIN',
      payload: {
        user: user?.data || {},
        typeStatuses,
        categories,
        categoriesDetail,
        cities,
        configs,
        siteAdPackages
      },
    });
  };

  // OneSignal listener notification
  const oneSignalHandler = async (user) => {
    await runOneSignal();
    OneSignal.getSubscription().then(isSubscribed => {
      if (isSubscribed) {
        // console.log('Update OneSignal user data ', user);
        OneSignal.setExternalUserId(user?.email);
      }
    });
  }

  const register = async (username, email, password, repassword, firstName, lastName) => {
    const response = await axios.post('api/v1/users/register/', {
      username,
      email,
      password1: password,
      password2: repassword,
      first_name: firstName,
      last_name: lastName
    });
    const { access, user } = response.data;

    localStorage.setItem('accessToken', access);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        googleLogin,
        facebookLogin,
        logout,
        register,
        verify,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
