import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from '../../utils/axios';
import { dispatch } from "../store";


const NOTIFICATION_ENDPOINT = "api/v1/users/noty/get/"
const UPDATE_NOTI_ENDPOINT = "api/v1/users/noty/post/"
const LIMIT_NOTY_NUMBER = 10
const NOTY_BELL_TYPE = "noty_to_client"

const initialState = {
    bellNotiList: [],
    anotherNotiType: {},
    error: null,
    isLoading: false,
    totalUnread: 0,
}


const slice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getNotificationsSuccess(state, action) {
            const { data, unread_count: unreadCount } = action.payload
            state.isLoading = false;
            state.bellNotiList = data;
            state.totalUnread = unreadCount;
        },

        addNewNotySuccess(state, action) {
            const newNoty = action.payload
            const { type } = newNoty

            state.isLoading = false;
            
            if(type === NOTY_BELL_TYPE){
                state.totalUnread += 1;
                state.bellNotiList = removeNotyDuplicate([newNoty,...state.bellNotiList])
                if (state.bellNotiList.length > LIMIT_NOTY_NUMBER) {
                    const _temp = [...state.bellNotiList]
                    _temp.length = LIMIT_NOTY_NUMBER
                    state.bellNotiList = removeNotyDuplicate([..._temp])
                }
            }
            else {
                state.anotherNotiType = newNoty
            }
        },

        markNotiAsReadSuccess(state, action) {
            const notificationIds = action.payload;

            state.isLoading = false;
            state.bellNotiList = updateNotyList(state.bellNotiList, notificationIds);
            if (notificationIds.length === 0) {
                state.totalUnread = 0;
            }
            else {
                state.totalUnread -= 1
                state.totalUnread = state.totalUnread > 0 ? state.totalUnread : 0;
            }
        },
    }
})

export default slice.reducer;
export const { startLoading } = slice.actions;


export function getNotifications() {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const notifications = await axios.get(NOTIFICATION_ENDPOINT)
            dispatch(slice.actions.getNotificationsSuccess({ data: notifications.data.results, unread_count: notifications.data.unread_count }))

        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

export function markNotiAsRead(notiIds = []) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            await axios.put(UPDATE_NOTI_ENDPOINT, { id: [...notiIds] })
            dispatch(slice.actions.markNotiAsReadSuccess(notiIds))

        } catch (error) {
            dispatch(slice.actions.hasError(error))

        }
    }
}

export function addNewNoty(newNoty = {}) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            dispatch(slice.actions.addNewNotySuccess(newNoty))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

const removeNotyDuplicate = (arr = []) => {
    const _temp = [...arr]
    return _.uniqBy(_temp, (e) => e.id)
}

const updateNotyList = (arr = [], notificationIds = []) => {
    const _temp = [...arr]
    return _temp.map(item => {
        if (notificationIds.includes(item.id)) {
            return { ...item, data: { is_read: true } };
        }
        // mark all as read
        if (notificationIds.length === 0 && !Object.prototype.hasOwnProperty.call(item.data, "expiration")) {
            return { ...item, data: { is_read: true } };
        }
        return item;
    })
}