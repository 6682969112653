import { useState, useCallback, useRef, useEffect } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, IconButton, InputAdornment, Link, Stack, Typography, FormControlLabel, Switch } from '@mui/material';
// GoogleReCaptcha
import {
  useGoogleReCaptcha,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useSettings from '../../../hooks/useSettings';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useResponsive from '../../../hooks/useResponsive';
// components
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
// configs
import { ENVIRONMENT, SOCIAL_AUTH_FACEBOOK_KEY, SOCIAL_AUTH_GOOGLE_KEY, HOST_API } from '../../../config';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, googleLogin, facebookLogin, verify } = useAuth();
  const { themeMode } = useSettings();

  const [searchParams, setSearchParams] = useSearchParams();

  const {enqueueSnackbar} = useSnackbar();

  const [googleResponseCaptcha, setGoogleResponseCaptcha] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [isUseEmail, setUseEmail] = useState(false);

  const smUp = useResponsive('up', 'sm');

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const [isSubmittingGoogle, setSubmittingGoogle] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    (async ()=>{
      try {
        // Query params
        // Facebook
        const token = searchParams.get('token');
        const refresh = searchParams.get('refresh');
        const loginType = searchParams.get('login_type');
        // Google
        const code = searchParams.get('code');
        // Hidden query params on URL
        setSearchParams({});
        // Verify token
        if (token && refresh && loginType && ['facebook', 'google-auth2'].indexOf(loginType) > -1) {
          await verify(token, refresh);
        } else if(code) {
          console.log('submit google 1st');
          setSubmittingGoogle(true);
          await googleLogin(code);
          setSubmittingGoogle(false);
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [searchParams]);
  

  const onSubmit = async (data) => {
    try {
      // get recaptcha token
      const token = await executeRecaptcha('login');
      await login(data.email, data.password, token);
      setRefreshReCaptcha(r => !r);
    } catch (error) {
      console.error(error);

      reset();

      if (isMountedRef.current) {
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  }

  const onVerify = (token) => {
    setGoogleResponseCaptcha(token);
  };

  const onChangeUseEmail = (use) => {
    setUseEmail(use);
  }

  const loginGoogle = async (code) => {
    if (code) {
      console.log('loginGoogle');
      setSubmittingGoogle(true);
      await googleLogin(code);
      setSubmittingGoogle(false);
    }
    else {
      return enqueueSnackbar('Đăng nhập thất bại', { variant: "error" })
    }
  }

  const loginWithGoogle = useGoogleLogin({
    onSuccess: response => loginGoogle(response?.code),
    onError: error => console.log(`Google login error: ${error}`),
    flow: 'auth-code',
    ux_mode: 'redirect',
  });
  // Handle login with facebook
  const handleLoginWithFacebook = ()=>{
    window.location.href = `${process.env.REACT_APP_HOST_API_KEY}login/facebook/`;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            Đăng nhập Uptin
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>Đăng nhập nhanh với</Typography>
        </Box>
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
        <LoadingButton
          fullWidth
          variant="text"
          size="large"
          startIcon={
            <Typography
              variant="div"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                heught: '100%',
              }}
            >
              <Iconify icon="flat-color-icons:google" color="#DF3E30" width={'18px'} height={'18px'} />
            </Typography>
          }
          onClick={loginWithGoogle}
          loading={isSubmittingGoogle}
          sx={{
            height: '40px',
            width: '100%',
            pr: '10px',
            py: '12px',
            pl: '1px',
            justifyContent: 'start',
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            color: '#FFFFFF',
            backgroundColor: '#4285F4',
            fontFamily: `'Roboto', sans-serif`,
            boxShadow: `0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)`,
            border: '1px solid #4285F4',
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: '#4285F4',
              boxShadow: `0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168)`,
            },
            '& .MuiButton-iconSizeLarge': {
              ml: '1px',
              mr: '10px !important',
              display: 'flex',
              height: '36px',
              width: '36px',
              backgroundColor: '#FFFFFF',
              p: '10px',
              borderRadius: '1px',
            },
          }}
        >
          Sign in with Google
        </LoadingButton>
        <LoadingButton
          fullWidth
          variant="text"
          size="large"
          startIcon={<Iconify icon="ion:logo-facebook" width={24} height={24} />}
          onClick={handleLoginWithFacebook}
          sx={{
            height: '40px',
            justifyContent: 'start',
            textTransform: 'none',
            backgroundColor: '#1877F2',
            color: '#fff',
            fontWeight: 500,
            outlineColor: '#1877F2',
            p: '0px 12px 0px 12px',
            border: '1px solid #1877F2',
            borderRadius: '4px',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: '#fff',
              color: '#1877F2',
            },
          }}
        >
          Login with Facebook
        </LoadingButton>
      </Stack>
      <FormControlLabel
        control={<Switch checked={isUseEmail} onChange={(event) => onChangeUseEmail(event.target.checked)} />}
        label="Đăng nhập bằng email"
      />
      {isUseEmail && (
        <>
          <Box sx={{ m: 2, color: 'inherit' }} className="separator">
            HOẶC
          </Box>
          {ENVIRONMENT !== 'production' && (
            <Alert severity="info" sx={{ mb: 3 }}>
              Tài khoản dùng thử : <strong>demo@uptin.vn</strong> / Mật khẩu :<strong> demo123</strong>
            </Alert>
          )}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              {!!errors.afterSubmit && <Alert severity="error">Email hoặc mật khẩu không đúng</Alert>}
              {!!errors.afterSubmit && (
                <Alert severity="error">Bạn có thể đăng nhập bằng Google thay cho Email và Mật Khẩu</Alert>
              )}
              <RHFTextField name="email" label="Email" />

              <RHFTextField
                name="password"
                label="Mật khẩu"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <RHFCheckbox name="remember" label="Nhớ tài khoản" />
              <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgetPassword}>
                Quên mật khẩu?
              </Link>
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Đăng nhập
            </LoadingButton>
          </FormProvider>
        </>
      )}

      {!smUp && (
        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
          Chưa có tài khoản?{' '}
          <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
            Đăng ký ngay
          </Link>
        </Typography>
      )}
    </>
  );
}
