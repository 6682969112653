import _ from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Typography, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Label from '../../../components/Label';
import { useDispatch, useSelector } from '../../../redux/store';
import { getSites } from '../../../redux/slices/site';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    label: "Trang", align: "left",
  },

  {
    label: "Tình trạng", align: "left",
  },

  {
    label: "Tương thích", align: "left",
  },
]

const NOTY_SITE_STATUS_TYPE = "noty_to_checker"

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const theme = useTheme()
  const [open, setOpen] = useState(null);
  const dipatch = useDispatch()
  const [_sites, _setSites] = useState([])
  const {sites} = useSelector(state => state.site)
  const { anotherNotiType } = useSelector(state => state.noti)

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    dipatch(getSites())
  },[])

  useEffect(() => {
    if ((!_.isEmpty(anotherNotiType) && anotherNotiType.type === NOTY_SITE_STATUS_TYPE)) {
      const { compatible, is_accessible: isAccessible, is_active: isActive} = _.get(anotherNotiType, "data", "")
      _setSites(prevState => {
        let temp = []
        if(_.isEmpty(prevState)){
          temp = [...sites]
        }
        else{
          temp = [...prevState]
        }
        temp = temp.map(site => site.id === +_.get(anotherNotiType, "target_id", "") ? 
            { ...site, is_active: isActive, is_accessible: isAccessible, compatible } : site)
        return temp
      })
    }
  }, [anotherNotiType])

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'mingcute:earth-line'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: "inherit", height: "50%", overflowY: "auto",
          '& .MuiMenuItem-root': {
            px: 1.5,
            borderRadius: 0.75,
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Số trang liên kết <Typography component="span">({sites.length})</Typography>
        </Typography>


        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((column, index) => (
                <TableCell style={{ "backgroundColor": "white" }} key={index} align={column.align}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {_.sortBy(!_.isEmpty(_sites) ? [..._sites] : [...sites] ,(e) => e.alias).map((site) => (
              <TableRow key={site.id}>
                <TableCell>
                  {site.alias}
                </TableCell>

                <TableCell>
                  <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={identifySiteStatus(site.is_active, site.is_accessible).color}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {identifySiteStatus(site.is_active ,site.is_accessible).label}
                  </Label>
                </TableCell>

                <TableCell>
                  {`${site.compatible.toString()}%`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MenuPopover>
    </>
  );
}

const identifySiteStatus = (isActive, isAccessible) => {
  let result = {}
  if(!isActive){
    result = {
      label: "Tạm ngưng",
      color: "error"
    }
  }
  else if(isActive && isAccessible){
    result = {
      label: "Đang hoạt động",
      color: "success"
    }
  }
  else if(isAccessible && !isAccessible){
    result = {
      label: "Đang sửa lỗi",
      color: "warning"
    }
  }
  return result
}
