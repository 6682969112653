import React, { useEffect,createContext, useState } from 'react'
import { w3cwebsocket as W3CWebSocket } from 'websocket';
// hooks
import useAuth from '../hooks/useAuth';
// config
import { HOST_API } from '../config';

const WS_HOST_API = HOST_API.replace('https://', 'wss://').replace('http://', 'ws://')

const initialState = {
  wsClient: null,
  // Send notification
  handleSendNotification: () => {},
};

const WebSocketContext = createContext(initialState);

function WebSocketProvider({children}) {
  const { user } = useAuth();
  const [wsClient, setWebSocketClient] = useState(null);

    useEffect(() => {
      if(user){
        const temp = new W3CWebSocket(`${WS_HOST_API}ws/notification/${user.email}/`);
        
        temp.onopen = () => {
          console.log('WebSocket Client Connected');
        };

        temp.onmessage = (message) => {
          // const dataFromServer = JSON.parse(message.data);
          // if (dataFromServer) {
          //   console.log('Websocket data', dataFromServer);
          //   // test form api created success
          //   alert(dataFromServer.message);
          // }
        };
        
        setWebSocketClient(temp);
      }
    }, [user]);

    // Send notifciation
  const handleSendNotification = (actionType,message,sender,receiver)=>{
    // Receiver,Sender must be a email address
    wsClient.send(
      JSON.stringify({
        action_type: actionType,
        message,
        sender,
        receiver,
      })
    );
  }
  return (
    <WebSocketContext.Provider
      value={{
        wsClient,
        handleSendNotification,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export {WebSocketProvider, WebSocketContext};