import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_ASSET_URL = 'api/v1/assets/';

const initialState = {
  isLoading: false,
  error: null,
  assets: []
};

const slice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ASSETS
    getAssetsSuccess(state, action) {
      state.isLoading = false;
      state.assets = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

async function getAssetByUrl(url, assets) {
  const response = await axios.get(url);
  if (response?.data) {
    const { results, next } = response.data;
    results.forEach((result) => {
      assets.push({
        id: result?.uuid,
        label: result?.title,
        images: result?.images || [],
        title: result?.title,
        createdAt: result?.created_at,
        city: result?.city_name,
        description: result?.description
      })
    });
    if(next) {
      const page = next.split('?')[1];
      await getAssetByUrl(`${BASE_ASSET_URL}?${page}`, assets);
    }
  }
}

export function getAssets() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const assets = [];
      await getAssetByUrl(BASE_ASSET_URL, assets);
      dispatch(slice.actions.getAssetsSuccess(assets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Get 10 assets latest
export async function getLatestAssets() {
  try {
    const assets = [];
    const response = await axios.get(`${BASE_ASSET_URL}?ordering=-updated_at`);
    if (response?.data) {
      const { results } = response.data;
      results.forEach((result) => {
        assets.push({
          id: result?.uuid,
          label: result?.title,
          images: result?.images || [],
          title: result?.title,
          createdAt: result?.created_at,
          city: result?.city_name,
          description: result?.description,
        });
      });
      return assets;
    }
  } catch (error) {
    console.log({ error });
  }
}

// Get assets by keyword
export async function getAssetsByKeyword(keyword) {
  try {
    const assets = []
    const response = await axios.get(`${BASE_ASSET_URL}?filter[search]=${keyword}`);
    if (response?.data) {
      const { results } = response.data;
      results.forEach((result) => {
        assets.push({
          id: result?.uuid,
          label: result?.title,
          images: result?.images || [],
          title: result?.title,
          createdAt: result?.created_at,
          city: result?.city_name,
          description: result?.description,
          next: result?.next,
          previous:result?.previous,
          count: result?.count
        })
      });
    return assets
    }
  } catch (error) {
    console.log({error})
  }
}

// Get assets by keyword
export async function getAssetsWithPaginateByKeyword(keyword) {
  try {
    const assets = []
    const response = await axios.get(`${BASE_ASSET_URL}?filter[search]=${keyword}`);
    if (response?.data) {
      const { results,next, previous, count } = response.data;
      results.forEach((result) => {
        assets.push({
          ...result,
          id: result?.uuid,
          label: result?.title,
          images: result?.images || [],
          title: result?.title,
          createdAt: result?.created_at,
          city: result?.city_name,
          description: result?.description,
        })
      });
    return {
      assets,
      next,
      previous,
      count,
    };
    }
  } catch (error) {
    console.log({error})
  }
}

export async function getAssetsByUUID(uuid) {
  try {
    const assets = [];
    const response = await axios.get(`${BASE_ASSET_URL}${uuid}/`);
    if (response?.data) {
      const result = response.data
      return {
        ...result,
        id: result.uuid,
        city: result?.city?.name,
      };
    }
  } catch (error) {
    console.log({ error });
  }
}