import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, seperator = '/') {
  if(!isValidDate(date)) {
    return '';
  }
  return format(new Date(date), `dd${seperator}MM${seperator}yyyy`);
}

export function fTime(date, timeFormat = 'HH:mm:ss') {
  if(!isValidDate(date)) {
    return '';
  }
  return format(new Date(date), timeFormat);
}

export function fDateTime(date) {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

function isValidDate(date) {
  return date instanceof Date && !Number.isNaN(date);
}

export const convertDateString = (dateString, timeString) => {
  if (typeof dateString === "string") {
    const [year, month, date] = dateString.split("/").reverse()
    const [hour, minute, second] = timeString.split(":")

    return new Date(year, month - 1, date, hour, minute, second)
  }
  return false
}
