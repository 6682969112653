// hooks
import useAuth from '../../../hooks/useAuth';
// components
import Label from '../../../components/Label';

export default function FreePostPopover(){
    const {user} = useAuth();

    return(
        <Label color="info"> 
            {user?.free_daily_post_left}/{user?.free_daily_post}
        </Label>
    );
}
