// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------

export default function BlockContent({
  title = "Tối thiểu 3 ảnh - Tối đa 6 ảnh",
  text1 = "- Kéo thả hoặc nhấn chọn ảnh",
  text2 = "- Dung lượng ảnh tối đa 3Mb",
  text3 = "- Ảnh đầu tiên từ trái qua sẽ là ảnh bìa",
}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text1}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text2}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text3}
        </Typography>
      </Box>
    </Stack>
  );
}
