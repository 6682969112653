// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  plusMath: getIcon('ic_plus_math'),
  setting: getIcon('ic_setting'),
  share: getIcon('ic_share'),
  list: getIcon('ic_list'),
  notification: getIcon('ic_notification'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Tổng quan', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'Tin', path: PATH_DASHBOARD.post.list, icon: ICONS.list },
      { title: 'Lịch', path: PATH_DASHBOARD.calendar, icon: ICONS.booking },
      { 
        title: 'Thị trường', 
        path: PATH_DASHBOARD.general.analytics, 
        icon: ICONS.analytics,
      },
      // { 
      //   title: 'Chat', 
      //   path: PATH_DASHBOARD.chat.root, 
      //   icon: ICONS.chat, 
      //   info: <Label color="error">Soon</Label> 
      // },
      {
        title: 'Báo',
        path: PATH_DASHBOARD.notification.root,
        icon: ICONS.notification,
        info: <Label color="error">Soon</Label> 
      },
      {
        title: 'Hóng',
        path: PATH_DASHBOARD.blog.posts,
        icon: ICONS.share,
        info: <Label color="error">Soon</Label> 
      },
    ],
  },

  // QUICK ACTION
  // ----------------------------------------------------------------------
  {
    subheader: ' ',
    items: [

      // ASSET CREATE
      {
        title: 'Soạn tin',
        path: PATH_DASHBOARD.post.root,
        icon: ICONS.plusMath,
        children: [
          { title: 'Bất động sản', path: PATH_DASHBOARD.post.newRealEstate },
          { title: 'Mạng xã hội', path: PATH_DASHBOARD.post.newSocial }
        ]
      },
      // SITE ACCOUNT
      {
        title: 'Tài khoản',
        path: PATH_DASHBOARD.account.list,
        icon: ICONS.user
      },

      // SETTINGS
      {
        title: 'Cài đặt',
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.setting
      },
    ],
  },
];

export default navConfig;
