import { findIndex, remove } from 'lodash'
import { createSlice } from '@reduxjs/toolkit';
// utils
import { fDate } from '../../utils/formatTime';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

// const BASE_EVENT_URL = 'api/v1/scheduled/';
// const HISTORY_EVENT_URL = 'api/v1/scheduled/history/';
const LIST_SCHEDULED_URL = 'api/v1/scheduled/group/ids/';
const LIST_HISTORY_SCHEDULED_URL = 'api/v1/scheduled/group/history/ids/';
const BASE_EVENT_GROUP_URL = 'api/v1/scheduled/group/';
const HISTORY_EVENT_GROUP_URL = 'api/v1/scheduled/group/history/';
const DETAIL_SCHEDULED_URL = 'api/v1/scheduled/detail/';
const initialState = {
  isLoading: false,
  error: null,
  events: [],
  isOpenModal: false,
  selectedEventId: null,
  selectedRange: null,
  allEvents: []
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      const oldEvents = [...state.events];
      // Remove dulicate
      const concatedArray = action.payload.concat(oldEvents);
      const list = concatedArray.filter(
        (item, pos) =>
          concatedArray.findIndex((item1) => {
            // compare values:  miliseconds + asset_uuid + scheduled_post_status
            return (
              item.time.getTime() === item1.time.getTime() &&
              item.uuid === item1.uuid &&
              item1.postStatus === item.postStatus
            );
          }) === pos
      );

      // Events have group property
      state.events = [...list];
    },
    // UPDATE LIST EVENTS INFO 
    loadAllEventsInfo(state, action) {
      state.isLoading = false;
      const oldEvents = [...state.allEvents];
      // Remove dulicate
      const concatedArray = action.payload.concat(oldEvents);
      const list = concatedArray.filter((item, pos) => concatedArray.findIndex((item1) => item.id === item1.id) === pos);

      // List all events
      state.allEvents = [...list];
    },
    // CREATE EVENT
    createEventSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.events = [...state.events, newEvent];
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.events = updateEvent;
    },

    // DELETE EVENT
    deleteEventSuccess(state, action) {
      const { eventId, eventUuid, eventTime, eventSite } = action.payload;

      const eventsTmp = [...state.events]

      const index = findIndex(eventsTmp, (item) => item.uuid === eventUuid && new Date(item.time).getTime() === new Date(eventTime).getTime())
      const selectedEvent = eventsTmp.find(item => item.uuid === eventUuid && new Date(item.time).getTime() === new Date(eventTime).getTime())
      const numberSite = selectedEvent.site_ids.length

      if (numberSite <= 1) {
        remove(eventsTmp, (item) => item.uuid === eventUuid && new Date(item.time).getTime() === new Date(eventTime).getTime())
      }
      else {
        const temp = {
          ...selectedEvent,
          site_ids: selectedEvent.site_ids.filter(item => item !== eventSite),
          scheduled_ids: selectedEvent.scheduled_ids.filter(item => item !== eventId)
        }
        eventsTmp[index] = temp
      }

      state.isLoading = false;
      state.events = eventsTmp;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

const scheduleColorMap = {
  1: '#FFC107',
  2: '#FF4842',
  3: '#00AB55',
}

async function getEventsByUrl(url, params) {
  const response = await axios.get(url, { 'params': params });
  if (response?.data) {
    const { results, next } = response.data;
    const newEvents = []
    results.filter((res) => res.scheduled_post_status !== 4).forEach((asset) => {
      const startDate = new Date(asset.datetime);
      const dateString = fDate(startDate, '-').split('-').reverse().join('-');
      newEvents.push({
        id: asset.id,
        note: asset.note,
        uuid: asset.asset_uuid,
        start: dateString,
        end: dateString,
        title: asset.title,
        description: asset.note,
        site: asset.site_identity,
        time: new Date(asset.datetime),
        postStatus: asset.scheduled_post_status,
        textColor: scheduleColorMap[asset.scheduled_post_status],
      });
    });
    dispatch(slice.actions.getEventsSuccess(newEvents));
    if (next && undefined === params.page) {
      // first call
      const count = response.data.count;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getEventsByUrl(url, params);
        })()
      }
    }
  }
}

async function getEventsGroupByUrl(url, params) {
  const response = await axios.get(url, { params });
  if (response?.data) {
    const { results, next } = response.data;
    const newEvents = [];
    results
      .filter((res) => res.scheduled_post_status !== 4)
      .forEach((asset) => {
        const startDate = new Date(asset.datetime);
        const dateString = fDate(startDate, '-').split('-').reverse().join('-');
        newEvents.push({
          id: asset.asset_uuid,
          uuid: asset.asset_uuid,
          start: dateString,
          end: dateString,
          title: asset.asset_title,
          time: new Date(asset.datetime),
          scheduled_ids: asset.scheduled_ids,
          status_ids: asset.status_ids,
          site_ids: asset.site_ids,
        });
      });
    dispatch(slice.actions.getEventsSuccess(newEvents));

    if (next && undefined === params.page) {
      // first call
      const count = response.data.count;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getEventsGroupByUrl(url, params);
        })();
      }
    }
  }
}

export async function loadEventById(id) {
  // Get detail info of each event
  try {
    const response = await axios.get(`${DETAIL_SCHEDULED_URL}${id}/`);
    let results = response.data;
    results = [results].map((asset) => {
      const startDate = new Date(asset.datetime);
      const dateString = fDate(startDate, '-').split('-').reverse().join('-');
      return {
        id: asset.id,
        note: asset.note,
        uuid: asset.asset_uuid,
        start: dateString,
        end: dateString,
        title: asset.title,
        description: asset.note,
        site: asset.site_identity,
        time: new Date(asset.datetime),
        postStatus: asset.scheduled_post_status,
        textColor: scheduleColorMap[asset.scheduled_post_status],
      };
    });
    dispatch(slice.actions.loadAllEventsInfo(results));
  } catch (error) {
    console.log(error);
  }
}

// Load each event info to redux
export async function loadEventByIds(url, eventIds, params) {
  // Get detail info of each event
  const response = await axios.get(url, { params: { ...params,scheduled_ids : [...eventIds].join(',')} });
  const { next, results } = response.data;
  const newEvents = []
  results
    .filter((res) => res.scheduled_post_status !== 4)
    .forEach((asset) => {
      const startDate = new Date(asset.datetime);
      const dateString = fDate(startDate, '-').split('-').reverse().join('-');
      newEvents.push({
        id: asset.id,
        note: asset.note,
        uuid: asset.asset_uuid,
        start: dateString,
        end: dateString,
        title: asset.title,
        description: asset.note,
        site: asset.site_identity,
        time: new Date(asset.datetime),
        postStatus: asset.scheduled_post_status,
        textColor: scheduleColorMap[asset.scheduled_post_status],
      });
    });
  dispatch(slice.actions.loadAllEventsInfo(newEvents));

  if (next && undefined === params.page) {
    // first call
    const count = response.data.count;
    const pageSize = results.length;
    const concurrency = Math.ceil(count / pageSize);
    for (let page = 2; page <= concurrency; page += 1) {
      (async () => {
        params.page = page;
        await loadEventByIds(url, eventIds, params);
      })();
    }
  }
}

export function getEvents(date) {
  const dateString = date;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {


      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      // console.log('current', currentDate);
      // check if history previous month
      const split = dateString.split('/');
      const day = Number(split[0]);
      const month = Number(split[1]);
      const year = Number(split[2]);
      const chosenDate = new Date(year, month - 1, day);
      // console.log('chosen', chosenDate);
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      let firstDay = new Date(currentYear, currentMonth, 1);
      let lastDay = new Date(currentYear, currentMonth + 1, 0);
      if (currentDate.getTime() > chosenDate.getTime() && month !== currentMonth + 1) {
        // console.log('past events');
        firstDay = new Date(year, month - 1, 1);
        lastDay = new Date(year, month, 0);
        await getEventsGroupByUrl(HISTORY_EVENT_GROUP_URL, { dateStart: fDate(firstDay), dateEnd: fDate(lastDay) });
      } else if (month === currentMonth + 1 && year === currentYear) {
        // get monthly historical data and future data
        // console.log('this month: previous');
        await getEventsGroupByUrl(HISTORY_EVENT_GROUP_URL, { dateStart: fDate(firstDay), dateEnd: dateString });
        // console.log('this month: future');
        await getEventsGroupByUrl(BASE_EVENT_GROUP_URL, { dateStart: dateString, dateEnd: fDate(lastDay) });
      } else {
        // console.log('future events');
        await getEventsGroupByUrl(BASE_EVENT_GROUP_URL, { dateStart: dateString, dateEnd: fDate(lastDay) });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// export function getEvents(date) {
//   const dateString = date;
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const currentDate = new Date();
//       currentDate.setHours(0, 0, 0, 0);
//       // console.log('current', currentDate);
//       // check if history previous month
//       const split = dateString.split('/');
//       const day = Number(split[0]);
//       const month = Number(split[1]);
//       const year = Number(split[2]);
//       const chosenDate = new Date(year, month - 1, day);
//       // console.log('chosen', chosenDate);
//       const currentMonth = currentDate.getMonth();
//       const currentYear = currentDate.getFullYear();
//       let firstDay = new Date(currentYear, currentMonth, 1);
//       let lastDay = new Date(currentYear, currentMonth + 1, 0);
//       if (currentDate.getTime() > chosenDate.getTime() && month !== currentMonth+1) {
//         // console.log('past events');
//         firstDay = new Date(year, month - 1, 1);
//         lastDay = new Date(year, month, 0);
//         await getEventsByUrl(HISTORY_EVENT_URL, { 'dateStart': fDate(firstDay), 'dateEnd': fDate(lastDay) });
//       } else if (month === currentMonth + 1 && year === currentYear) {
//         // get monthly historical data and future data
//         // console.log('this month: previous');
//         await getEventsByUrl(HISTORY_EVENT_URL, { 'dateStart': fDate(firstDay), 'dateEnd': dateString });
//         // console.log('this month: future');
//         await getEventsByUrl(BASE_EVENT_URL, { 'dateStart': dateString, 'dateEnd': fDate(lastDay) });
//       } else {
//         // console.log('future events');
//         await getEventsByUrl(BASE_EVENT_URL, { 'dateStart': dateString, 'dateEnd': fDate(lastDay) });
//       } 
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
// ----------------------------------------------------------------------

export function createEvent(newSchedule) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/scheduled/create/', newSchedule);
      dispatch(slice.actions.createEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventId, updateEvent) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/calendar/events/update', {
        eventId,
        updateEvent,
      });
      dispatch(slice.actions.updateEventSuccess(response.data.event));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId, eventUuid, eventTime, eventSite) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.deleteEventSuccess({ eventId, eventUuid, eventTime, eventSite }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function selectRange(start, end) {
  return async () => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime(),
      })
    );
  };
}
