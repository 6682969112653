import { useEffect, useRef } from 'react'; 
import { GoogleOAuthProvider } from '@react-oauth/google';
import PWAPrompt from 'react-ios-pwa-prompt'
import ReactGA from "react-ga4";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// configs
import { SOCIAL_AUTH_GOOGLE_KEY, ENVIRONMENT } from './config'
import { UpdatePwaModal } from './components/UpdatePwaModal';

// ----------------------------------------------------------------------

const GG_TRACKING_ID ="G-BWF8243404"

export default function App() {

  const offIOSPrompt = useRef(true)

  ReactGA.initialize(GG_TRACKING_ID)

  // Zoho Sales Iq Script:
  useEffect(() => {
    if (ENVIRONMENT === 'production') {
      const script = document.createElement('script');
      script.setAttribute("type", "text/javascript");

      const widgetCode = 'd753d57d2f9988fa4c28bdcabdee7312ecac1ecfa6066d2976c56ad4b1fff64b';
      const url = 'https://salesiq.zoho.com/widget';

      const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
    }
  });

  return (
    <GoogleOAuthProvider clientId={SOCIAL_AUTH_GOOGLE_KEY}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <NotistackProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
            </NotistackProvider>
          </ThemeSettings>
        </ThemeProvider>
        <UpdatePwaModal/>
        {!offIOSPrompt.current && (
          <PWAPrompt 
            promptOnVisit={1} 
            timesToShow={30} 
            copyClosePrompt="Close" 
            permanentlyHideOnDismiss={false} 
            copyShareButtonLabel="1. Bấm vào nút 'Chia sẻ'"
            copyAddHomeButtonLabel="2. Chọn 'Thêm vào màn hình chính'"
            copyTitle="Thêm vào màn hình chính"
            copyBody="Bạn có thể cài đặt website chúng tôi như một app điện thoại. Hãy thêm icon app vào màn hình chính và sử dụng trên toàn màn hình."
          />
        )}
      </MotionLazyContainer>
    </GoogleOAuthProvider>
  );
}
